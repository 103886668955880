import { RolesEnum } from '../../../private_modules/dunice-space-shared/types';

export enum RiseUpLevelEnum {
  'TRAINEE' = 'Trainee',
  'JUNIOR' = 'Junior',
  'MIDDLE' = 'Middle',
  'SENIOR' = 'Senior',
}

export type RiseUpLevel = keyof typeof RiseUpLevelEnum;

export enum UserRoleEnum {
  developer = 'Разработчик',
  headOfDepartment = 'Руководитель отдела',
  techLead = 'Тех. лидер',
  teamLead = 'Ком. лидер',
  manager = 'Менеджер',
  admin = 'Админ',
  notApproved = 'Роль не назначена',
}

export type UserRole = keyof typeof RolesEnum;

export type Role = keyof typeof RolesEnum;
export const managementStaff: Role[] = [
  RolesEnum.internalAdmin,
  RolesEnum.admin,
  RolesEnum.headOfDevDepartment,
  RolesEnum.techlead,
  RolesEnum.manager,
  RolesEnum.unitlead,
  RolesEnum.headOfAcademy,
  RolesEnum.qaLead,
  RolesEnum.headOfGroup,
  RolesEnum.headOfUnit,
  RolesEnum.headOfProjectOfficeDepartment,
  RolesEnum.teamLead,
  RolesEnum.riseupStatistics,
];

export const students: Role[] = [
  RolesEnum.developer,
  RolesEnum.qa,
  RolesEnum.curator,
  RolesEnum.analytic,
  RolesEnum.devOps,
];

export enum SpecializationEnum {
  webFullstack = 'webFullstack',
  java = 'java',
  qa = 'qa',
  qamanual = 'qamanual',
  qaauto = 'qaauto',
  design = 'design',
  mobile = 'mobile',
  mobileIOS = 'mobileIOS',
  mobileAndroid = 'mobileAndroid',
  analytic = 'analytic',
  devOps = 'devOps',
}
